import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { url2section } from 'ion-sections'
import loadable from '@loadable/component'
import NewspaperButton from '../../../assets/images/buttons/dailynews-button.png'
import { DefaultSectionPageSkeletonWrapper, ArticlePageSkeleton } from '../components/Skeletons'

const TitleSectionPage = loadable(() => import('../pages/TitleSectionPage'), {
  fallback: <DefaultSectionPageSkeletonWrapper />
})
const TagPage  = loadable(() => import('../pages/TagPage'))
const Article = loadable(() => import('../pages/Article'), {
  fallback: <ArticlePageSkeleton />
})

const RenderArticle = (props) => {
  const { contentKey } = useParams()
  return <Article {...props} contentKey={contentKey} />
}

const DailyNews = (props) => (
  <Switch>
    <Route path='/dailynews' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews'
        sectionLabel='Daily News'
        relatedSections={[{ id: 'dailynews/news' }, { id: 'dailynews/world' }, { id: 'dailynews/sport' }, { id: 'dailynews/lifestyle' }, { id: 'dailynews/opinion' }, { id: 'dailynews/features' }, { id: 'dailynews/tonight' }, { id: 'dailynews/motoring' }, { id: 'dailynews/business' }, { id: 'dailynews/consumer' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/popular' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/popular'
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/news' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/news'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/news/kwazulu-natal' }, { id: 'dailynews/news/south-africa' }, { id: 'dailynews/news/africa' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/news/kwazulu-natal' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/news/kwazulu-natal'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/news' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/news/south-africa' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/news/south-africa'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/news' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/world' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/world'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/news' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/news/africa' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/news/africa'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/news' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/sport' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/sport'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/sport' }, { id: 'dailynews/lifestyle' }, { id: 'dailynews/opinion' }, { id: 'dailynews/features' }, { id: 'dailynews/tonight' }, { id: 'dailynews/motoring' }, { id: 'dailynews/business' }, { id: 'dailynews/consumer' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/lifestyle' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/lifestyle'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/lifestyle/health' }, { id: 'dailynews/lifestyle/fashion' }, { id: 'dailynews/lifestyle/food' }, { id: 'dailynews/lifestyle/books' }, { id: 'dailynews/lifestyle/home-garden', label: 'Home & Garden' }, { id: 'dailynews/lifestyle/family' }, { id: 'dailynews/lifestyle/relationships' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/lifestyle/health' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/lifestyle/health'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/lifestyle' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/lifestyle/fashion' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/lifestyle/fashion'
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/lifestyle/food' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/lifestyle/food'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/lifestyle' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/lifestyle/books' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/lifestyle/books'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/lifestyle' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/lifestyle/home-garden' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/lifestyle/home-garden'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/lifestyle' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/lifestyle/family' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/lifestyle/family'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/lifestyle' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/lifestyle/relationships' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/lifestyle/relationships'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/lifestyle' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/lifestyle/technology' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/lifestyle/technology'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/lifestyle' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/opinion' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/opinion'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/opinion/views-analysis', label: 'Views & Analysis' }, { id: 'dailynews/opinion/features' }, { id: 'dailynews/opinion/editorial' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/opinion/views-analysis' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/opinion/views-analysis'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/opinion' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/opinion/features' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/opinion/features'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/opinion' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/opinion/editorial' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/opinion/editorial'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/opinion' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/features' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/features'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/news' }, { id: 'dailynews/sport' }, { id: 'dailynews/lifestyle' }, { id: 'dailynews/opinion' }, { id: 'dailynews/tonight' }, { id: 'dailynews/motoring' }, { id: 'dailynews/business' }, { id: 'dailynews/consumer' }]}
        title='Daily News'
        description='Daily News features page'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/tonight' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/tonight'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/news' }, { id: 'dailynews/sport' }, { id: 'dailynews/lifestyle' }, { id: 'dailynews/opinion' }, { id: 'dailynews/features' }, { id: 'dailynews/motoring' }, { id: 'dailynews/business' }, { id: 'dailynews/consumer' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/motoring' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/motoring'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/news' }, { id: 'dailynews/sport' }, { id: 'dailynews/lifestyle' }, { id: 'dailynews/opinion' }, { id: 'dailynews/features' }, { id: 'dailynews/tonight' }, { id: 'dailynews/business' }, { id: 'dailynews/consumer' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/business' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/business'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/news' }, { id: 'dailynews/sport' }, { id: 'dailynews/lifestyle' }, { id: 'dailynews/opinion' }, { id: 'dailynews/features' }, { id: 'dailynews/tonight' }, { id: 'dailynews/motoring' }, { id: 'dailynews/consumer' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path='/dailynews/consumer' exact render={() => (
      <TitleSectionPage {...props}
        section='dailynews/consumer'
        relatedSections={[{ id: 'dailynews', label: 'Daily News' }, { id: 'dailynews/news' }, { id: 'dailynews/sport' }, { id: 'dailynews/lifestyle' }, { id: 'dailynews/opinion' }, { id: 'dailynews/features' }, { id: 'dailynews/tonight' }, { id: 'dailynews/motoring' }, { id: 'dailynews/business' }]}
        title='Daily News'
        description='-'
        publication='Daily News'
        newspaperTitle='The Daily News on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://dailynews.zinioapps.com/'
      />
    )} />
    <Route path={'/dailynews/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} oovvuu='4c0314aa-87fa-4a57-8152-521cc269c23c' />
    )} />
    <Route path={'/dailynews/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} oovvuu='4c0314aa-87fa-4a57-8152-521cc269c23c' />
    )} />
    <Route path={'/dailynews/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9]{5,})'} exact component={RenderArticle} />
    <Route path={'/dailynews/:title*([-])*:contentKey([0-9]{5,})'} exact component={RenderArticle} />
    <Route path={'/dailynews/:section([a-zA-Z0-9-/]+)'} exact render={() => (
      <TagPage {...props}
        section={url2section(props.location.pathname)}
        url={props.location.pathname}
      />
    )} />
  </Switch>
)

export default DailyNews
